import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FeedbackMessage, Logger, useAnalytics } from '@adatree/atomic-components';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { handleErrorRedirect, trackError } from '../../../utils/errors/errors.util';
import { CONSTANTS } from '../../../app/consts/app.const';
import { DashboardAnalyticsEvents } from '../../../app/consts/analytics.const';
import { consentApi } from '@adatree/react-api-sdk';
import queryString from 'query-string';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';

export const ConsentCallback: React.FC = () => {
  const navigate = useNavigate();
  const { hash, search } = useLocation();
  const { appSettings } = useAppSettings();
  const { track } = useAnalytics();
  const {
    patchConsentAuthorization,
    isMutating,
    error: patchApiError,
  } = consentApi.usePatchConsentAuthorizationTrigger();
  const [isProcessing, setIsProcessing] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const consentFlowType = sessionStorage.getItem(CONSTANTS.storageKeys.consentFlowType);
  const consentId = sessionStorage.getItem(CONSTANTS.storageKeys.authConsentId);
  const querySearchParams = queryString.parse(search);
  const response: string = querySearchParams.response as string;
  const queryHashParams = queryString.parse(hash);
  const state: string = queryHashParams.state as string;
  const dataHolderError: string = queryHashParams.error as string;
  const errorDescription: string = queryHashParams.error_description as string;

  const initialized = useRef(false);

  useEffect(() => {
    const parseError = async () => {
      let error = 'server_error';
      let errorDescription = 'Unexpected Error has occured will proecessing the patch consent authorization request.';

      if (patchApiError.response.status === 424) {
        const responseJson = await patchApiError.response.json();
        error = responseJson.dataHolderErrorMessage;
        errorDescription = responseJson.dataHolderErrorDescription;
      }

      if (appSettings.consent.errorRedirectUrl) {
        handleErrorRedirect(appSettings.consent.errorRedirectUrl, error, errorDescription);
      } else {
        setErrorMessage(`${error} - ${errorDescription}`);
        setIsProcessing(false);
      }
    };

    if (!isMutating && patchApiError) {
      parseError();
    }
  }, [isMutating, patchApiError, appSettings.consent.errorRedirectUrl]);

  useEffect(() => {
    if (initialized.current) {
      return;
    }

    const handleSuccess = (id: string) => {
      track(DashboardAnalyticsEvents.CONSENT_SUCCESS, '', '', '');

      const allowMultiConsent = sessionStorage.getItem(CONSTANTS.storageKeys.allowMultiConsent);

      if (allowMultiConsent && allowMultiConsent === 'true') {
        navigate(`${URL_SETTINGS.POST_CONSENT_FLOW.url}?${CONSTANTS.urlParamsKeys.consentId}=${id}`);
      } else if (appSettings.consent.successCreateConsentRedirectUrl) {
        const redirect = appSettings.consent.successCreateConsentRedirectUrl.replace('[consentId]', id);
        Logger.info(`Redirecting to ${redirect}`);
        window.location.href = redirect;
      } else {
        navigate(URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', id));
      }
    };

    const handleError = (code: string, message: string, error?: Error) => {
      track(DashboardAnalyticsEvents.CONSENT_SUCCESS, '', '', '', code);

      if (error) {
        Logger.error(code, message, error);
        trackError(error);
      } else {
        Logger.error(code, message);
      }

      if (appSettings.consent.errorRedirectUrl) {
        handleErrorRedirect(appSettings.consent.errorRedirectUrl, code, message);
      }

      setErrorMessage(`${code} - ${message}`);
      setIsProcessing(false);
    };

    const callProcessAuthorization = async () => {
      initialized.current = true;

      Logger.info('Calling Process Authorization flow');
      const consentId = sessionStorage.getItem(CONSTANTS.storageKeys.authConsentId);

      if (consentId) {
        const patchResponse = await patchConsentAuthorization({
          consentId: consentId,
          dataHolderAuthorizationResponse: {
            response: response,
          },
        }).catch((error) => {
          Logger.error('Error calling patchConsentAuthorization', error);
        });
        if (patchResponse) {
          Logger.info('Update successfull', patchResponse);
          handleSuccess(consentId);
        }
      } else if (!consentId) {
        handleError('mis_para_err', 'Consent Callback is missing the Consent ID');
      }
    };

    if (dataHolderError) {
      handleError(
        dataHolderError,
        errorDescription ? errorDescription : `Consent Callback failed due to error ${dataHolderError}`
      );
    } else if (response) {
      callProcessAuthorization();
    } else {
      handleError('mis_para_err', 'Consent Callback did not received all the required hash parameters:');
    }
  }, [
    state,
    navigate,
    appSettings.consent.successCreateConsentRedirectUrl,
    appSettings.consent.errorRedirectUrl,
    appSettings.consent.enableExtendConsent,
    dataHolderError,
    errorDescription,
    patchConsentAuthorization,
    response,
    track,
    consentId,
    isMutating,
    consentFlowType,
  ]);

  return (
    <>
      {isProcessing && <FeedbackMessage message="Processing your response..." showSpinner={true} />}
      {!isProcessing && errorMessage && (
        <FeedbackMessage
          message={`Sorry we were not able to process your request. ${errorMessage}.`}
          icon={<AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />}
        />
      )}
      {!isProcessing && !errorMessage && (
        <FeedbackMessage message="Consent created." icon={<Check sx={{ fontSize: '56px', color: 'primary.main' }} />} />
      )}
    </>
  );
};
