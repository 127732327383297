import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorMessage, Logger } from '@adatree/atomic-components';
import { PageWrapper } from '../../components/atoms/page-wrapper.atom';
import { handleErrorRedirect, parseError } from '../../utils/errors/errors.util';
import { Box } from '@mui/material';
import { AppSettings } from '../settings/app.settings.types';

interface Props {
  appSettings: AppSettings;
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
}

export class ErrorBoundary extends Component<Props, State> {
  public appSettings: AppSettings;

  constructor(props) {
    super(props);
    this.appSettings = props.appSettings;
    this.state = { hasError: false, error: undefined };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: _ };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.appSettings.consent.errorRedirectUrl) {
      const appError = parseError('Application error', this.state.error);
      handleErrorRedirect(this.appSettings.consent.errorRedirectUrl, appError.code, appError.message);
    }

    Logger.error('Application error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const appError = parseError('Application error', this.state.error);
      return (
        <PageWrapper>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                maxWidth: '90vw',
              },
            })}
          >
            <ErrorMessage
              message={appError.message}
              code={appError.code}
              data={appError.data}
              type={appError.type}
              timeStamp={appError.timeStamp}
            />
          </Box>
        </PageWrapper>
      );
    }

    return this.props.children;
  }
}
