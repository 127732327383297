import { Logger } from '@adatree/atomic-components';
import { CONSTANTS } from '../../../app/consts/app.const';
import { RedirectOverwrites } from './url-parameter.type';

const getParameters = (): URLSearchParams => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  Logger.debug(`Retrieved URL params:`, params);

  return params;
};

const getRedirectOverwrites = (): RedirectOverwrites => {
  const params = getParameters();

  let createRedirect = undefined;
  let manageRedirect = undefined;
  let errorRedirect = undefined;

  const createRedirectUrlParam = params.get(CONSTANTS.urlParamsKeys.createConsentRedirect);
  const manageRedirectUrlParam = params.get(CONSTANTS.urlParamsKeys.manageConsentRedirect);
  const errorRedirectUrlParam = params.get(CONSTANTS.urlParamsKeys.appErrorRedirect);

  // Url parameters are higher priority than values in session storage
  if (createRedirectUrlParam) {
    createRedirect = createRedirectUrlParam;
    sessionStorage.setItem(CONSTANTS.storageKeys.createConsentRedirect, createRedirect);
  } else {
    createRedirect = sessionStorage.getItem(CONSTANTS.storageKeys.createConsentRedirect);
  }

  if (manageRedirectUrlParam) {
    manageRedirect = manageRedirectUrlParam;
    sessionStorage.setItem(CONSTANTS.storageKeys.manageConsentRedirect, manageRedirect);
  } else {
    manageRedirect = sessionStorage.getItem(CONSTANTS.storageKeys.manageConsentRedirect);
  }

  if (errorRedirectUrlParam) {
    errorRedirect = errorRedirectUrlParam;
    sessionStorage.setItem(CONSTANTS.storageKeys.appErrorRedirect, errorRedirect);
  } else {
    errorRedirect = sessionStorage.getItem(CONSTANTS.storageKeys.appErrorRedirect);
  }

  return {
    createConsentRedirect: createRedirect,
    manageConsentRedirect: manageRedirect,
    appErrorRedirect: errorRedirect,
  };
};

export const UrlParameter = {
  getParameters,
  getRedirectOverwrites,
};
