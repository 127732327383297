import * as React from 'react';
import { AppTheme } from '@adatree/atomic-components';
import { AppSettings } from '../app/settings/app.settings.types';

const AppSettingsContext = React.createContext<{ appSettings: AppSettings; appTheme: AppTheme }>(undefined);

const useAppSettings = () => {
  const context = React.useContext(AppSettingsContext);
  if (!context) {
    throw new Error(`useAppSettings must be used within a AppSettingsProvider`);
  }
  return context;
};

type AppSettingsProviderProps = {
  children: React.ReactNode;
  settings: AppSettings;
};

const AppSettingsProvider = ({ children, settings }: AppSettingsProviderProps) => {
  const [appSettings] = React.useState<AppSettings>(settings);
  const theme = settings.theme;
  const [appTheme] = React.useState<AppTheme>(theme);

  return <AppSettingsContext.Provider value={{ appSettings, appTheme }}>{children}</AppSettingsContext.Provider>;
};

export { AppSettingsProvider, useAppSettings };
