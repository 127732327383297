import React, { useEffect, useState } from 'react';
import { ErrorMessage, FeedbackMessage } from '@adatree/atomic-components';
import { PageWrapper } from '../../components/atoms/page-wrapper.atom';
import { useAuth } from '../hooks/auth/auth.hook';
import { TestModeMenu } from '../../components/molecules/test-mode-menu/test-mode-menu.molecule';
import { ApiProvider } from '@adatree/react-api-sdk';
import { useAppSettings } from '../../providers/app-settings.provider';
import { AuthUtil } from '../../utils/auth/auth.util';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const auth = useAuth();
  const { appSettings } = useAppSettings();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await AuthUtil.getAccessToken();
      setAccessToken(token);
    };

    getAccessToken();
  }, []);

  useEffect(() => {
    if (!auth.isLoading && !auth.error && !auth.isAuthenticated) {
      auth.signIn();
    }
  }, [auth]);

  return (
    <>
      {auth.isLoading && (
        <PageWrapper>
          <FeedbackMessage message="Please wait while we send you to your authentication provider." showSpinner />
        </PageWrapper>
      )}
      {auth.error && (
        <PageWrapper>
          <ErrorMessage code="AUTH_001" message={auth.error.message} />
        </PageWrapper>
      )}
      {auth.isAuthenticated && accessToken && (
        <>
          <ApiProvider
            accessToken={accessToken}
            apiUrl={`${appSettings.api.backendProtocol}://${appSettings.api.backendDomain}`}
          >
            <TestModeMenu />
            {children}
          </ApiProvider>
        </>
      )}
    </>
  );
};
