import React, { ReactNode } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { AuthUtil } from '../utils/auth/auth.util';
import { URL_SETTINGS } from '../app/settings/url.settings';
import { useAppSettings } from './app-settings.provider';
import { CONSTANTS } from '../app/consts/app.const';
import { State } from '../app/state/state';
import { AuthType } from '../context/authentication/auth.context';
import { Logger } from '@adatree/atomic-components';

interface Props {
  children: ReactNode;
}

export const AuthenticationProvider: React.FC<Props> = ({ children }: Props) => {
  const navigate = useNavigate();
  const { appSettings } = useAppSettings();

  if (!appSettings.oidc) {
    State.setAuthType(AuthType.OTP);
  }

  // The App's code requires React OIDC AuthProvider regardless of the
  // auth flow (OIDC or OTP). This is an empty oidc config for OPT flow
  let oidcConfig = {
    userManager: undefined,
    autoSignIn: false,
    onSigninCallback: undefined,
  };

  if (State.getAuthType() === AuthType.OIDC) {
    const authConfig = AuthUtil.initAuthentication(appSettings);

    oidcConfig = {
      userManager: authConfig,
      autoSignIn: !appSettings.application.simulateAuthentication,
      onSigninCallback: async () => {
        // Clear the auth code and state parameters from the URL
        window.history.replaceState({}, document.title, window.location.pathname);

        const returnUri = localStorage.getItem(CONSTANTS.storageKeys.returnUri);

        if (returnUri) {
          localStorage.removeItem(CONSTANTS.storageKeys.returnUri);

          if (appSettings.consent.disableCreateConsent && returnUri.startsWith(URL_SETTINGS.CONSENT_CREATE.url)) {
            Logger.info('disableCreateConsent is set to true, navigate to consent list.');
            navigate(URL_SETTINGS.CONSENT_LIST.url);
          } else {
            navigate(returnUri);
          }
        } else {
          if (appSettings.consent.disableCreateConsent) {
            navigate(URL_SETTINGS.CONSENT_LIST.url);
          } else {
            navigate(URL_SETTINGS.CONSENT_CREATE.url);
          }
        }
      },
    };
  }

  return (
    <>
      {State.getAuthType() === AuthType.OIDC && (
        <AuthProvider
          {...oidcConfig}
          skipSigninCallback={window.location.pathname === URL_SETTINGS.CONSENT_CALLBACK.url}
        >
          {children}
        </AuthProvider>
      )}
      {State.getAuthType() === AuthType.OTP && <AuthProvider skipSigninCallback={true}>{children}</AuthProvider>}
    </>
  );
};
