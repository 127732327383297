import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

interface Props {
  children: ReactElement;
  height?: string;
}

export const CenterLayout = ({ children, height = '100vh' }: Props) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: height }}
      >
        <Grid item xs={3}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
