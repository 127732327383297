import { Breadcrumbs, Link, Typography } from '@mui/material';
import { UrlItem } from '../../../app/settings/url.settings';

interface BreadcrumbProps {
  breadcrumbs: UrlItem[];
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { breadcrumbs } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => {
        if (index === breadcrumbs.length - 1) {
          return (
            <Typography color="text.primary" key={breadcrumb.url}>
              {breadcrumb.label}
            </Typography>
          );
        }

        return (
          <Link underline="hover" color="inherit" href={breadcrumb.url} key={breadcrumb.url}>
            {breadcrumb.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
