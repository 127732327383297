import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { FormInput } from './form-input.type';

export const FormInputNumber = ({ name, label, control, rules }: FormInput) => {
  let isRequired = false;

  if (rules && rules.required) {
    isRequired = true;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TextField
          sx={{ mb: 2 }}
          defaultValue={value}
          helperText={error ? error.message : ' '}
          size="small"
          type="number"
          error={!!error}
          onChange={onChange}
          fullWidth
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            required: isRequired,
          }}
        />
      )}
    />
  );
};
