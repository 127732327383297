import { useEffect, useState } from 'react';
import { Logger, useDataRecipients, LinkExternal, ConsentTabs } from '@adatree/atomic-components';
import { Box, Button, Typography } from '@mui/material';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { consentApi, ConsentResponse, FindAllConsentsV2Request } from '@adatree/react-api-sdk';

import ChevronLeft from 'mdi-material-ui/ChevronLeft';

type PaginationModel = {
  page: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
};

export const ConsentList = () => {
  const defaultPage = 1;
  const defaultPageSize = 25;
  const { getConsents, isMutating } = consentApi.useGetConsentsV2Trigger();
  const { appSettings } = useAppSettings();
  const { primaryDataRecipient } = useDataRecipients();
  const [activeConsents, setActiveConsents] = useState<ConsentResponse[]>();
  const [expiredConsents, setExpiredConsents] = useState<ConsentResponse[]>();
  const [revokedConsents, setRevokedConsents] = useState<ConsentResponse[]>();
  const [pagination, setPagination] = useState<PaginationModel>({
    page: defaultPage,
    pageSize: defaultPageSize,
    totalPages: 1,
    totalRecords: 25,
  });

  useEffect(() => {
    let request: FindAllConsentsV2Request = { status: 'ACTIVE', page: defaultPage, pageSize: defaultPageSize };
    getConsents(request).then((data) => {
      setPagination({
        page: defaultPage,
        pageSize: defaultPageSize,
        totalPages: data.meta.totalPages,
        totalRecords: data.meta.totalRecords,
      });
      setActiveConsents(data.consents);
    });
    // eslint-disable-next-line
  }, []);

  const handleChange = (tab: number, page: number) => {
    let request: FindAllConsentsV2Request = { status: 'ACTIVE', page: page, pageSize: defaultPageSize };

    if (tab === 1) {
      request = { ...request, status: 'EXPIRED' };
    } else if (tab === 2) {
      request = { ...request, status: 'REVOKED' };
    }

    getConsents(request).then((data) => {
      setPagination({
        page: page,
        pageSize: defaultPageSize,
        totalPages: data.meta.totalPages,
        totalRecords: data.meta.totalRecords,
      });

      if (tab === 0) {
        setActiveConsents(data.consents);
      } else if (tab === 1) {
        setExpiredConsents(data.consents);
      } else if (tab === 2) {
        setRevokedConsents(data.consents);
      }
    });
  };

  const handleClick = () => {
    const redirect = appSettings.consent.successManageConsentRedirectUrl;
    Logger.info(`Redirecting to ${redirect}`);
    window.location.href = redirect;
  };

  return (
    <Box>
      {appSettings.consent.successManageConsentRedirectUrl && (
        <Button sx={{ mb: 2 }} variant="text" color="inherit" startIcon={<ChevronLeft />} onClick={() => handleClick()}>
          Back
        </Button>
      )}

      <ConsentTabs
        activeConsents={activeConsents}
        expiredConsents={expiredConsents}
        revokedConsents={revokedConsents}
        pagination={pagination}
        onChange={handleChange}
        isLoading={isMutating}
      />

      {appSettings.consent.successManageConsentRedirectUrl && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button sx={{ mt: 2 }} variant="contained" onClick={() => handleClick()}>
            Done
          </Button>
        </Box>
      )}
      <Typography variant="body2" sx={{ mt: 4 }}>
        Please read our <LinkExternal href={primaryDataRecipient.cdrPolicyUrl} text={'Consumer Data Right Policy'} />{' '}
        for more information on how {primaryDataRecipient.name} handles your data.
      </Typography>
    </Box>
  );
};
