import React, { Suspense } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import { PrivateRoute } from './private-route';
import { URL_SETTINGS } from '../settings/url.settings';
import { PageWrapper } from '../../components/atoms/page-wrapper.atom';
import { AuthenticationCallback } from '../../components/organisms/authentication-callback/authentication-callback.organism';
import { ConsentCallback } from '../../components/organisms/consent-callback/consent-callback.organism';
import { ConsentCreateEdge } from '../../components/organisms/consent-create/consent-create-edge.organism';
import { ConsentDetails } from '../../components/organisms/consent-details/consent-details.organism';
import { ConsentFormProvider } from '@adatree/atomic-components';
import { ConsentEdit } from '../../components/organisms/consent-edit/consent-edit.organism';
import { ConsentList } from '../../components/organisms/consent-list/consent-list.organism';
import { OtpSignInPage } from '../../components/organisms/otp-sign-in/otp-sign-in.page';
import { PasswordlessSignInPage } from '../../components/organisms/passwordless-sign-in/passwordless-sign-in.page';
import { PostConsentFlowPage } from '../../components/pages/post-consent-flow/post-consent-flow.page';
const ErrorPage = React.lazy(() => import('../../components/organisms/error/error.organism'));
const TestLabPage = React.lazy(() => import('../../components/organisms/test-lab/test-lab.organism'));
const LogoutPage = React.lazy(() => import('../../components/organisms/logout/logout.organism'));
const StatusPage = React.lazy(() => import('../../components/organisms/status/status.organism'));

export const Routes = () => {
  return (
    <ReactRoutes>
      <Route
        path={URL_SETTINGS.ERROR.url}
        element={
          <PageWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <ErrorPage />
            </Suspense>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.STATUS.url}
        element={
          <PageWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <StatusPage />
            </Suspense>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.TEST_LAB.url}
        element={
          <PageWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <PrivateRoute>
                <TestLabPage />
              </PrivateRoute>
            </Suspense>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.LOGOUT.url}
        element={
          <PageWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <LogoutPage />
            </Suspense>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.AUTH_CALLBACK.url}
        element={
          <PageWrapper>
            <AuthenticationCallback />
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.OTP_SIGN_IN.url}
        element={
          <PageWrapper width="xs">
            <OtpSignInPage />
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.PASSWORDLESS_SIGN_IN.url}
        element={
          <PageWrapper>
            <PasswordlessSignInPage />
          </PageWrapper>
        }
      />

      <Route
        path={URL_SETTINGS.CONSENT_CALLBACK.url}
        element={
          <PageWrapper width="sm">
            <PrivateRoute>
              <ConsentCallback />
            </PrivateRoute>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.CONSENT_CREATE.url}
        element={
          <PageWrapper width="sm">
            <PrivateRoute>
              <ConsentFormProvider>
                <ConsentCreateEdge />
              </ConsentFormProvider>
            </PrivateRoute>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.CONSENT_DETAIL.url}
        element={
          <PageWrapper>
            <PrivateRoute>
              <ConsentDetails />
            </PrivateRoute>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.CONSENT_EDIT.url}
        element={
          <PageWrapper>
            <PrivateRoute>
              <ConsentFormProvider>
                <ConsentEdit />
              </ConsentFormProvider>
            </PrivateRoute>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.CONSENT_LIST.url}
        element={
          <PageWrapper>
            <PrivateRoute>
              <ConsentList />
            </PrivateRoute>
          </PageWrapper>
        }
      />
      <Route
        path={URL_SETTINGS.POST_CONSENT_FLOW.url}
        element={
          <PageWrapper width="sm">
            <PrivateRoute>
              <PostConsentFlowPage />
            </PrivateRoute>
          </PageWrapper>
        }
      />
    </ReactRoutes>
  );
};
