import * as React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { AuthenticationProvider } from '../../providers/authentication.provider';
import { CONSTANTS } from '../../app/consts/app.const';
import { Logger } from '@adatree/atomic-components';
import { State } from '../../app/state/state';
import { AuthUtil } from '../../utils/auth/auth.util';
import { OtpAuthManager } from '../../app/types/auth.type';
import { trackError } from '../../utils/errors/errors.util';

export enum AuthType {
  OIDC = 'OIDC',
  OTP = 'OTP',
  PWL = 'PWL',
}

type AuthContextType = {
  granteeId: string;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const gid: string = queryParams.gid as string;
  const [granteeId] = React.useState<string>(gid);

  const storedAuthType = sessionStorage.getItem(CONSTANTS.storageKeys.authType);
  const storedOtpAuthManager = sessionStorage.getItem(CONSTANTS.storageKeys.otpAuthManager);

  if (storedAuthType) {
    if (Object.values(AuthType).includes(AuthType[storedAuthType])) {
      State.setAuthType(AuthType[storedAuthType]);
    }
    sessionStorage.removeItem(CONSTANTS.storageKeys.authType);
  }

  if (storedOtpAuthManager) {
    try {
      const otpAuthManager: OtpAuthManager = JSON.parse(storedOtpAuthManager);
      if (otpAuthManager.accessTokenExpires && AuthUtil.isAccessTokenExpired(otpAuthManager.accessTokenExpires)) {
        Logger.warn('OTP access token has expired. Auto sign out the user.', otpAuthManager);
        AuthUtil.signout();
      } else {
        State.setOtpAuthManager(otpAuthManager);
      }
    } catch (error) {
      Logger.error(`Error parsing stored OTP Auth Manager ${storedOtpAuthManager}`);
      trackError(error);
    }
  }

  return (
    <AuthContext.Provider value={{ granteeId }}>
      <AuthenticationProvider>{children}</AuthenticationProvider>
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
