import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorMessage, FeedbackMessage } from '@adatree/atomic-components';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { useAuth } from '../../../app/hooks/auth/auth.hook';
import { AuthUtil } from '../../../utils/auth/auth.util';
import { URL_SETTINGS } from '../../../app/settings/url.settings';

type ErrorObject = {
  code?: string;
  data?: object | string;
  type?: string;
  timeStamp?: Date;
  url?: string;
  supportContact?: string;
};

export const PasswordlessSignInPage = () => {
  const [searchParams] = useSearchParams();
  const [processing, setProcessing] = useState(true);
  const [errorUserMessage, setErrorUserMessage] = useState<string>();
  const [errorResponse, setErrorResponse] = useState<ErrorObject>();
  const { appSettings } = useAppSettings();
  const token = searchParams.get('token');
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const authenticate = async (token: string) => {
      try {
        const response = await fetch(`https://${appSettings.api.backendDomain}/passwordless-sign-in`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Success:', response, data);

          auth.postSignIn(AuthUtil.parseOtpSignInResponse(data, undefined), undefined);
          setProcessing(false);
          navigate(URL_SETTINGS.CONSENT_CREATE.url);
        }
        if (!response.ok) {
          if (response.status === 401) {
            setErrorUserMessage(
              'You are unable to sign in with this link. You are only able to use a sign in link once. This link has expired or has already been used.'
            );
          }
          setProcessing(false);

          setErrorResponse({
            code: response.status.toString(),
            data: response.body,
            type: response.type,
            timeStamp: new Date(),
            url: response.url,
          });
        }
      } catch (error) {
        setProcessing(false);
        console.error('Error:', error);
        setErrorResponse({
          code: 'FE_ERR',
          data: error,
          type: 'FE_ERR',
          timeStamp: new Date(),
          url: URL_SETTINGS.PASSWORDLESS_SIGN_IN.url,
        });
      }
    };
    if (token) {
      authenticate(token);
    }
    // eslint-disable-next-line
  }, [appSettings.api.backendDomain, token]);

  return (
    <Box sx={{ maxWidth: '50rem', m: '0 auto' }}>
      {!token && <ErrorMessage message={'Invaild request. URL parameter token is not found'} />}
      {token && processing && <FeedbackMessage showSpinner message={'Signing you in'}></FeedbackMessage>}
      {errorResponse && (
        <ErrorMessage
          message={'Unable to sign in'}
          code={errorResponse.code}
          data={errorResponse.data}
          type={errorResponse.type}
          timeStamp={errorResponse.timeStamp}
          url={errorResponse.url}
          userMessage={errorUserMessage}
        />
      )}
    </Box>
  );
};
