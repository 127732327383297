import { FeedbackMessage } from '@adatree/atomic-components';
import { useSearchParams } from 'react-router-dom';
import Check from 'mdi-material-ui/Check';
import Error from 'mdi-material-ui/AlertCircle';

export const AuthenticationCallback = () => {
  const [searchParams] = useSearchParams();
  let error: string = searchParams.get('error');
  let errorDescription: string = searchParams.get('error_description');

  error = error !== null ? `${decodeURI(error)}` : '';
  errorDescription = errorDescription !== null ? ` - ${decodeURI(errorDescription)}` : '';

  return (
    <>
      {!error && (
        <FeedbackMessage
          message="You have successfully logged in."
          icon={<Check sx={{ fontSize: '56px', color: 'primary.main' }} />}
        ></FeedbackMessage>
      )}
      {error && (
        <FeedbackMessage
          message={`Sign in failed. ${error} ${errorDescription}`}
          icon={<Error sx={{ fontSize: '56px', color: 'error.main' }} />}
        ></FeedbackMessage>
      )}
    </>
  );
};
