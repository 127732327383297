import { UserManager } from 'oidc-client-ts';
import { AuthType } from '../../context/authentication/auth.context';
import { AppError } from '../../utils/errors/errors.util';
import { ConfigResponse } from '../configuration/config-response.type';
import { OtpAuthManager } from '../types/auth.type';

/* Simple state system for the app, can always upgrade to Redux or similar if required */

declare global {
  interface Window {
    adt: AppState;
    adtConfig: Promise<ConfigResponse>;
  }
}

type AppState = {
  authType: AuthType;
  authManager: UserManager | null;
  otpAuthManager: OtpAuthManager | null;
  appError: AppError | null;
};

export const initState = (): AppState => {
  let authType = AuthType.OIDC;
  let otpAuthManager = null;

  window.adt = {
    authType: authType,
    authManager: null,
    otpAuthManager: otpAuthManager,
    appError: null,
  };
  return window.adt;
};

/** Getters & Setters **/

const getAuthManager = (): UserManager => {
  return window.adt.authManager;
};

const setAuthManager = (authentication: UserManager): void => {
  window.adt.authManager = authentication;
};

const getAuthType = (): AuthType => {
  return window.adt.authType;
};

const setAuthType = (type: AuthType): void => {
  window.adt.authType = type;
};

const getOtpAuthManager = (): OtpAuthManager => {
  return window.adt.otpAuthManager;
};

const setOtpAuthManager = (authManager: OtpAuthManager): void => {
  window.adt.otpAuthManager = authManager;
};

const getError = (): AppError => {
  if (window.adt.appError === null) {
    return null;
  }
  const error = {
    ...window.adt.appError,
  };
  return error;
};

const setError = (error: AppError): void => {
  window.adt.appError = error;
};

export const State = {
  getAuthManager,
  setAuthManager,
  getAuthType,
  setAuthType,
  getOtpAuthManager,
  setOtpAuthManager,
  getError,
  setError,
};
