// Referrence https://docs.mixpanel.com/docs/data-structure/events-and-properties

import { AnalyticsComponentMeta, AnalyticsEvents } from '@adatree/atomic-components';

export const DashboardAnalyticsEvents = {
  ...AnalyticsEvents,
  CONSENT_SUCCESS: 'Consent callback success',
  CONSENT_FAILURE: 'Consent callback failure',
};

export const DashboardAnalyticsComponentMeta = {
  ...AnalyticsComponentMeta,
  TEST_TRACKING_BT: { id: 'TEST_TRACKING_BT', description: 'Test tracking button' },
};
