import { ReactNode, useEffect, useState } from 'react';
import { ConsentEdit as Edit, FeedbackMessage, useConsentForm, Logger } from '@adatree/atomic-components';
import {
  consentApi,
  useCaseApi,
  ConsentResponse,
  ConsentsList,
  UpdateConsentRequest,
  UseCaseResponse,
} from '@adatree/react-api-sdk';
import { Box, Button, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { AlertCircle, Check, ChevronLeft } from 'mdi-material-ui';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { v4 as uuidv4 } from 'uuid';
import { CONSTANTS } from '../../../app/consts/app.const';
import { State } from '../../../app/state/state';
import { trackError } from '../../../utils/errors/errors.util';

type ConsentEditParams = {
  consentId: string;
};

export const ConsentEdit = () => {
  const navigate = useNavigate();
  const { appSettings } = useAppSettings();
  const { consentId } = useParams<ConsentEditParams>();
  const { getConsents } = consentApi.useGetConsentsV2Trigger();
  const { updateConsent } = consentApi.useUpdateConsentTrigger();
  const { getUseCases } = useCaseApi.useGetUseCasesTrigger();
  const { postConsentAuthorization, isMutating, error } = consentApi.usePostConsentAuthorizationTrigger();
  const [consentForm] = useConsentForm();
  const [consent, setConsent] = useState<ConsentResponse>();
  const [useCase, setUseCase] = useState<UseCaseResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState<ReactNode>();
  const [redirect, setRedirect] = useState<string | undefined>();

  useEffect(() => {
    if (redirect && !isMutating && !error) {
      window.location.href = redirect;
    } else if (error) {
      Logger.error('Edit consent authorization error', error);
      trackError(error);
      renderFeedback('Sorry we were not able to update your consent. Please try again later.', false, true);
    }
  }, [redirect, isMutating, error]);

  useEffect(() => {
    getConsents({ consentId }).then((consentList: ConsentsList) => {
      setConsent(consentList.consents[0]);
    });
  }, [consentId, getConsents]);

  useEffect(() => {
    if (consent && consent.consentId) {
      getUseCases({ active: true, combineScopes: true }).then((useCases: UseCaseResponse[]) => {
        const foundUseCase = useCases.find((useCase) => {
          return useCase.id === consent.useCase.id;
        });

        setUseCase(Object.assign({}, foundUseCase));
        setIsLoading(false);
      });
    }
  }, [consent, getUseCases]);

  const handleCancel = () => {
    navigate(URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', consentId));
  };

  const handleSubmit = () => {
    let updateConsentRequest: Partial<UpdateConsentRequest> = {
      updateConsent: {
        postUsageAction: consentForm.postUsageAction,
      },
    };

    const updateNewConsent = async () => {
      renderFeedback('Updating your consent...', true);

      const state = uuidv4();

      // Store the state and redirect Uri for future security check
      sessionStorage.setItem(state, appSettings.api.adhRedirectUri);
      sessionStorage.setItem(CONSTANTS.storageKeys.consentFlowType, CONSTANTS.consentFlowType.edit);
      sessionStorage.setItem(CONSTANTS.storageKeys.authConsentId, consentId);
      sessionStorage.setItem(CONSTANTS.storageKeys.authState, state);
      sessionStorage.setItem(CONSTANTS.storageKeys.authType, State.getAuthType());

      renderFeedback(`Please wait while we redirect you to ${consent.dataHolderName}`);

      const uriResponse = await postConsentAuthorization({
        consentId: consent.consentId,
        consentAuthorizationRequest: {
          state: state,
          sharingEndDate: consentForm.sharingEndDate.toISOString(),
          redirectUri: appSettings.api.adhRedirectUri,
        },
      });

      if (uriResponse && uriResponse.uri) {
        setRedirect(uriResponse.uri);
      }
    };

    const callUpdateConsent = async () => {
      try {
        renderFeedback('Saving your consent request...', true);
        await updateConsent({ consentId, updateConsent: updateConsentRequest.updateConsent });

        // Do not need to send the conesnt to the DH as this is an internal update only
        //
        // renderFeedback(`Please wait while we redirect you to ${consentForm.dataHolder.brandName}`);
        // const redirect = await consentRepository.authorization(
        //   updatedConsent.dataHolderBrandId,
        //   updatedConsent.consentId
        // );

        window.location.href = URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', consentId);
      } catch (error) {
        renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
      }
    };

    if (appSettings.consent.enableExtendConsent) {
      updateNewConsent();
    } else {
      callUpdateConsent();
    }
  };

  /**
   * Feedback
   */
  const renderFeedback = (message: string, isLoading = false, isError = false) => {
    const icon =
      isError === true ? (
        <AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />
      ) : (
        <Check sx={{ fontSize: '56px', color: 'primary.main' }} />
      );

    setFeedback(<FeedbackMessage message={message} icon={icon} showSpinner={isLoading} />);
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
      {!isLoading && (
        <>
          <Box>
            {feedback && feedback}
            {!feedback && consent && (
              <>
                <Button
                  sx={{ mb: 2 }}
                  variant="text"
                  color="inherit"
                  startIcon={<ChevronLeft />}
                  href={URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', consentId)}
                >
                  Back
                </Button>
                <Edit consent={consent} useCase={useCase} onCancel={handleCancel} onSubmit={handleSubmit} />
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};
