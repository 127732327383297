import { ConfigJsonLoader } from './config-json-loader.organism';
import { Logger } from '@adatree/atomic-components';
import { ConfigResponse } from '../../../app/configuration/config-response.type';
import { APP_FLAGS } from '../../../app/consts/app.flags';

const loadConfig = async (): Promise<ConfigResponse> => {
  Logger.debug('Loading S3 config');

  // Check for new config flag
  const queryParameters = new URLSearchParams(window.location.search);
  const useNewConfig = queryParameters.get(APP_FLAGS.useNewConfig) === 'true' ? true : false;
  const s3Config = await ConfigJsonLoader.fetchConfig('/app-config.json');

  if (useNewConfig) {
    // New config flow
    Logger.info('Using new config flow');

    if (s3Config.useApiConfig && s3Config.useApiConfig === true) {
      Logger.debug('S3 config.useApi set to true. Loading API config');
      const oldConfigPromise = ConfigJsonLoader.fetchConfig('/dashboard-config');
      // Temp calling the same endpoint until new config API is rolled out
      const newConfigPromise = ConfigJsonLoader.fetchConfig('/dashboard-config');

      Promise.all([oldConfigPromise, newConfigPromise]).then((values) => {
        Logger.debug('Old and New configs', values[0], values[1]);
        const mergedConfig = { ...values[0], ...values[1] };
        return mergedConfig;
      });
    }
  } else {
    // Old config flow
    Logger.debug('Using old config flow');

    if (s3Config.useApiConfig && s3Config.useApiConfig === true) {
      Logger.debug('S3 config.useApi set to true. Loading API config');
      return await ConfigJsonLoader.fetchConfig('/dashboard-config');
    }
  }

  return s3Config;
};

export const ConfigLoader = {
  loadConfig,
};
