import { UseCaseSelector } from '@adatree/atomic-components';
import { UseCaseResponse } from '@adatree/react-api-sdk';
import { Button, Typography } from '@mui/material';

export type ConsentCreateManyUseCaseProps = {
  useCases: UseCaseResponse[];
  onChange: (useCaseId: string) => void;
  onCancel: () => void;
};

export const ConsentCreateManyUseCase = (props: ConsentCreateManyUseCaseProps) => {
  const { useCases, onChange, onCancel } = props;

  const handleChange = (useCaseId: string) => {
    onChange(useCaseId);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Choose your Use Case
      </Typography>
      <UseCaseSelector useCases={useCases} onChange={handleChange} />
      <Button variant="outlined" color="inherit" onClick={handleCancel} sx={{ mt: 2 }}>
        Cancel
      </Button>
    </>
  );
};
