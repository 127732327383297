import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { ThemeOptions } from '@mui/material';
import { AppTheme } from '@adatree/atomic-components';

export const themeOverride = (appTheme?: AppTheme): Partial<ThemeOptions> => {
  const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
    (props, ref) => {
      const { href, ...other } = props;
      // Map href (MUI) -> to (react-router)
      return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
    }
  );

  let buttonBorderRadius = '4px';

  if (appTheme?.components?.button?.borderRadius) {
    buttonBorderRadius = appTheme.components?.button?.borderRadius;
  }

  return {
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiListItemButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            borderRadius: buttonBorderRadius,
          },
        },
      },
      MuiFab: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  };
};
