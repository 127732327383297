import React, { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { ReactElement, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { AnalyticsProvider, Helper, Logger } from '@adatree/atomic-components';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { DashboardAnalyticsEvents } from '../../../app/consts/analytics.const';

interface ProviderProps {
  children: ReactElement | ReactElement[];
}

export const MixpanelAnalytics = ({ children }: ProviderProps) => {
  const { appSettings } = useAppSettings();
  const location = useLocation();
  const auth = useAuth();
  const mixpanelToken = 'b81f93c2e061f8ca52370effffcab3d8';
  const [prevPathname, setPrevPathname] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);
  const [isIdentifyTracked, setIsIdentifyTracked] = useState(false);
  const primaryRecipient = Helper.getPrimaryDataRecipients(appSettings.dataRecipients);

  useEffect(() => {
    if (mixpanelToken && !isInitialized) {
      Logger.debug('Analytics: Initialize Tracker');
      mixpanel.init(mixpanelToken, { debug: true });
      mixpanel.set_config({ persistence: 'localStorage' });
      setIsInitialized(true);
    }
    if (isInitialized && !isIdentifyTracked && auth.user?.profile?.email) {
      Logger.debug('Analytics: Track identify', auth.user.profile.email);
      mixpanel.identify(auth.user.profile.email);
      setIsIdentifyTracked(true);
    }
    const curPathname = location.pathname;
    if (isInitialized && prevPathname !== curPathname) {
      Logger.debug(`Analytics: Track Page change from ${prevPathname} to ${curPathname}`);
      setPrevPathname(curPathname);
      trackPageLoad(location.pathname);
    }
    // eslint-disable-next-line
  }, [location, isInitialized, isIdentifyTracked, auth]);

  const mixpanelTrack = (event: string, properties: Record<string, string | string[]>) => {
    if (isInitialized) {
      Logger.debug('Analytics: Send track event', event, addCommonProperties(properties));
      mixpanel.track(event, addCommonProperties(properties));
    }
  };

  const handleOnTrack = (
    event: string,
    componentId: string,
    componentDescription: string,
    action: string,
    value?: string
  ) => {
    if (isInitialized) {
      /**
       * Mixpanel Rules
       *
       * Custom property sent to Mixpanel MUST be Capitalised
       */
      let properties: Record<string, string | string[]> = {
        Action: action,
        'Component ID': componentId,
        'Component description': componentDescription,
      };

      if (value) {
        properties = { ...properties, Value: value };
      }

      mixpanelTrack(event, properties);
    }
  };

  /**
   * Mixpanel Rules
   *
   * Custom property sent to Mixpanel MUST be Capitalised
   */
  const addCommonProperties = (properties: Record<string, string | string[]>): Record<string, string | string[]> => {
    const withCommonProperties = {
      AppVersion: appSettings.application.version,
      PrimaryRecipient: primaryRecipient.name,
      Path: location.pathname,
      Stage: appSettings.stage,
      ...properties,
    };
    return withCommonProperties;
  };

  const trackPageLoad = (path: string) => {
    mixpanelTrack(DashboardAnalyticsEvents.PAGE_LOAD, { Path: path });
  };

  return (
    <>
      <AnalyticsProvider onTrack={handleOnTrack}>{children}</AnalyticsProvider>
    </>
  );
};
