import { useEffect, useState } from 'react';
import { ConsentDetails as Details, Logger, RevokeDialog } from '@adatree/atomic-components';
import { consentApi, AccessFrequency, Status, ConsentsList, ConsentResponse } from '@adatree/react-api-sdk';
import { Box, Button, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useFeatureFlag } from '../../../app/hooks/feature-flag/feature-flag.hook';
import { APP_FLAGS } from '../../../app/consts/app.flags';
import { useDataRecipients } from '@adatree/atomic-components';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { trackError } from '../../../utils/errors/errors.util';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';

type ConsentDetailsParams = {
  consentId: string;
};

export const ConsentDetails = () => {
  const { dataRecipients } = useDataRecipients();
  const [getFlag] = useFeatureFlag();
  const { getConsents } = consentApi.useGetConsentsV2Trigger();
  const { revoke } = consentApi.useRevokeConsentTrigger();
  const { consentId } = useParams<ConsentDetailsParams>();
  const pastUseCasetTitle = 'Data we received';
  const presentUseCasetTitle = 'Data we are currently receiving';
  const [consent, setConsent] = useState<ConsentResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [isProcessingRevoke, setIsProcessingRevoke] = useState<boolean>(false);
  const [useCasetTitle, setUseCasetTitle] = useState<string>(presentUseCasetTitle);
  const { appSettings } = useAppSettings();

  useEffect(() => {
    const callGetConsents = async () => {
      await getConsents({ consentId }).then((consentList: ConsentsList) => {
        if (consentList && consentList.consents && consentList.consents.length > 0) {
          setConsent(Object.assign({}, consentList.consents[0]));
          if (consentList.consents[0].status === Status.Expired || consentList.consents[0].status === Status.Revoked) {
            setUseCasetTitle(pastUseCasetTitle);
          }

          if (
            consentList.consents[0].useCase?.accessFrequency !== AccessFrequency.OnceOff &&
            (getFlag(APP_FLAGS.showEditConsentBtn) || appSettings.consent.enableExtendConsent)
          ) {
            setCanEdit(true);
          }
        }

        setIsLoading(false);
      });
    };

    callGetConsents();
  }, [consentId, getFlag, appSettings, getConsents]);

  const handleRevokeDialogClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleRevokeButtonClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleRevokeDialogButtonClick = () => {
    setIsProcessingRevoke(true);

    revoke({ consentId })
      .then(() => {
        setConsent({ ...consent, status: Status.Revoked });
        setUseCasetTitle(pastUseCasetTitle);
        setOpenConfirmationDialog(false);
        setIsProcessingRevoke(false);
      })
      .catch((error) => {
        Logger.error('Error handling Revoke', error);
        trackError(error);
        setOpenConfirmationDialog(false);
        setIsProcessingRevoke(false);
      });
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
      {!isLoading && (
        <>
          <Box>
            <Button
              sx={{ mb: 2 }}
              variant="text"
              color="inherit"
              startIcon={<ChevronLeft />}
              href={URL_SETTINGS.CONSENT_LIST.url}
            >
              Back
            </Button>

            <Details
              consent={consent}
              dateTitle={'Key dates'}
              useCasetTitle={useCasetTitle}
              onRevokeClick={handleRevokeButtonClick}
              dataRecipients={dataRecipients}
              editUrl={canEdit ? URL_SETTINGS.CONSENT_EDIT.url.replace(':consentId', consentId) : undefined}
            />
          </Box>

          <RevokeDialog
            isOpen={openConfirmationDialog}
            isLoading={isProcessingRevoke}
            dataHolderName={consent.dataHolderName}
            onCancelClick={handleRevokeDialogClose}
            onRevokeClick={handleRevokeDialogButtonClick}
          />
        </>
      )}
    </>
  );
};
