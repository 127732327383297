import { Logger } from '@adatree/atomic-components';
import { ConfigResponse } from '../../../app/configuration/config-response.type';
import { AppSettings } from '../../../app/settings/app.settings.types';
import { trackError } from '../../../utils/errors/errors.util';

async function fetchConfig(url: string): Promise<ConfigResponse> {
  try {
    const response = await window.fetch(url, {
      method: 'GET',
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
    });

    const config: AppSettings = await response.json();

    if (!response.ok) {
      // Maintenance mode
      console.info('Redirect to maintenance page');
      window.location.href = `${window.location.origin}/maintenance.html`;
    }

    if (response.ok) {
      if (config) {
        const newConfig = Object.assign(config);

        if (!newConfig.application) {
          newConfig.application = {};
        }

        newConfig.application.timestamp = process.env.REACT_APP_TIMESTAMP || 'no-timestamp-set';
        newConfig.application.version = process.env.REACT_APP_VERSION || 'no-version-set';

        Logger.debug('Successfully loaded config', newConfig);

        return newConfig;
      } else {
        return Promise.reject(new Error(`No config found at "${url}"`));
      }
    } else {
      const error = new Error('Error calling fetchConfig');
      return Promise.reject(error);
    }
  } catch (error) {
    Logger.error('Error loading JSON config', error);
    trackError(error);
  }
}

export const ConfigJsonLoader = {
  fetchConfig,
};
