import {
  CopyBuilder,
  ConsentCreate,
  useCopy,
  useDataRecipients,
  Images,
  useConsentForm,
  Logger,
} from '@adatree/atomic-components';
import { Industry, UseCaseResponse, ConsentResponse } from '@adatree/react-api-sdk';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { useEffect, useState } from 'react';
import { ConsentCustomData } from '../consent-custom-data/consent-custom-data.organism';
import { CONSTANTS } from '../../../app/consts/app.const';
import { handleErrorRedirect } from '../../../utils/errors/errors.util';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
// import { Button } from '@mui/material';
// import ChevronLeft from 'mdi-material-ui/ChevronLeft';

export type ConsentCreateFlowOneUseCaseProps = {
  useCase: UseCaseResponse;
  existingConsents: ConsentResponse[];
  dataHolderId?: string;
  onCancel: () => void;
  onSubmit: () => void;
};

export const ConsentCreateOneUseCase = (props: ConsentCreateFlowOneUseCaseProps) => {
  const { useCase, existingConsents, dataHolderId, onCancel, onSubmit } = props;
  const [consentForm, setConsentForm] = useConsentForm();
  const [showCustomDataForm, setShowCustomDataForm] = useState(undefined);
  const { appSettings } = useAppSettings();
  // eslint-disable-next-line
  const [copy, setCopy] = useCopy();
  const { adrDataRecipient, primaryDataRecipient } = useDataRecipients();
  const allowMultiConsents = true;

  let favouriteDataHolders = [];

  favouriteDataHolders = useCase.dataHolders.filter((dataHolder) => {
    // Need to update the API definitions
    //@ts-ignore
    if (dataHolder.favourite) {
      //@ts-ignore
      return dataHolder.favourite === true;
    } else {
      return false;
    }
  });

  favouriteDataHolders = favouriteDataHolders.map((dataHolder) => {
    dataHolder.logoUri = Images.getHighResLogoUri(dataHolder);
    return dataHolder;
  });

  useEffect(() => {
    if (useCase.industries && useCase.industries.length > 0 && useCase.industries.includes(Industry.Energy)) {
      setCopy(CopyBuilder.generateCopy(adrDataRecipient, primaryDataRecipient, Industry.Energy));
    } else {
      setCopy(CopyBuilder.generateCopy(adrDataRecipient, primaryDataRecipient, Industry.Banking));
    }

    if (useCase && useCase.customDataSchema) {
      setShowCustomDataForm(true);
    } else {
      setShowCustomDataForm(false);
    }

    if (useCase && useCase.features && useCase.features.includes(CONSTANTS.useCase.features.multiConsent)) {
      sessionStorage.setItem(CONSTANTS.storageKeys.allowMultiConsent, 'true');
    } else {
      sessionStorage.removeItem(CONSTANTS.storageKeys.allowMultiConsent);
    }

    // eslint-disable-next-line
  }, [useCase]);

  const handleSubmit = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleNotListedClick = () => {
    const errorCode = CONSTANTS.errors.userDataHolderNotListed.code;
    const errorMessage = CONSTANTS.errors.userDataHolderNotListed.description;

    if (appSettings.consent.errorRedirectUrl) {
      handleErrorRedirect(appSettings.consent.errorRedirectUrl, errorCode, errorMessage);
    } else {
      Logger.info(errorMessage, `Error code: ${errorCode}`);
      window.location.href = URL_SETTINGS.CONSENT_CREATE.url;
    }
  };

  const handleCustomDataSubmit = (data) => {
    Logger.debug('Custom Data', data);
    consentForm.customData = data;
    setConsentForm({ ...consentForm });
    setShowCustomDataForm(false);
  };

  return (
    <>
      {showCustomDataForm && (
        <ConsentCustomData defaultValues={consentForm.customData} useCase={useCase} onSubmit={handleCustomDataSubmit} />
      )}
      {!showCustomDataForm && (
        <>
          {/* Need to update the API definitions */}
          {/* {useCase && useCase.customDataSchema && (
            <Button
              sx={{ mb: 2, pl: 0 }}
              variant="text"
              color="inherit"
              onClick={() => {
                consentForm.dataHolder = undefined;
                setConsentForm({ ...consentForm });
                setShowCustomDataForm(true);
              }}
            >
              <ChevronLeft /> Back
            </Button>
          )} */}

          <ConsentCreate
            existingConsents={existingConsents}
            useCase={useCase}
            dataHolderId={dataHolderId}
            enablePartnerMessageDiscreetMode={appSettings.consent.enablePartnerMessageDiscreetMode}
            favouriteDataHolders={favouriteDataHolders}
            hideMaxAccountConnectedBackButton={!appSettings.consent.errorRedirectUrl}
            allowMultiConsentsPerDataHolder={allowMultiConsents}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onNotListedClick={handleNotListedClick}
          />
        </>
      )}
    </>
  );
};
