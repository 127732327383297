import React from 'react';
import queryString from 'query-string';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { Logger } from '@adatree/atomic-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_SETTINGS } from '../../../app/settings/url.settings';

export const PostConsentFlowPage = () => {
  const { appSettings } = useAppSettings();
  const { search } = useLocation();
  const navigate = useNavigate();
  const querySearchParams = queryString.parse(search);
  const consentId: string = querySearchParams.cid as string;


  const handleNewConsent = () => {
    navigate(URL_SETTINGS.CONSENT_CREATE.url);
  }

  const handleExit = () =>{
    if (appSettings.consent.successCreateConsentRedirectUrl) {
      const redirect = appSettings.consent.successCreateConsentRedirectUrl.replace('[consentId]', consentId);
      Logger.info(`Redirecting to ${redirect}`);
      window.location.href = redirect;
    } else {
      navigate(URL_SETTINGS.CONSENT_LIST.url);
    }
  }

  return (
    <Box sx={{ pt:4, pb: 5 }}>
      <Stack alignItems="center" spacing={4}>
        <Typography>You have successfully added your account</Typography>
          <Button sx={{minWidth: '186px'}} variant='contained' onClick={handleNewConsent}>Add another account</Button>

          <Button sx={{minWidth: '186px'}} variant='contained' onClick={handleExit}>Finish</Button>
      </Stack>
    </Box>
  );
};
