import { Logger } from '@adatree/atomic-components';
import { AuthUtil } from '../../utils/auth/auth.util';
import { handleError } from '../../utils/errors/errors.util';
import { URL_SETTINGS } from '../settings/url.settings';

import { State } from '../state/state';
import { AuthType } from '../../context/authentication/auth.context';
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

export const setApiInterceptors = (navigate: NavigateFunction): void => {
  axios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      handleError('Request error', err);
      navigate(URL_SETTINGS.ERROR.url);
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 401: // Special OIDC use case for Salesforce auth provider
            if (State.getAuthType() === AuthType.OIDC) {
              Logger.warn(
                'Server responded with a 401, sign the user out of their Auth Provider since their access token is unauthorized.'
              );
              AuthUtil.signout();
            }
            if (State.getAuthType() === AuthType.OTP) {
              const otpAuthManager = State.getOtpAuthManager();
              if (
                otpAuthManager &&
                otpAuthManager.accessTokenExpires &&
                AuthUtil.isAccessTokenExpired(otpAuthManager.accessTokenExpires)
              ) {
                Logger.warn('Server responded with a 401, access token has expired. Auto sign out the user.');
                AuthUtil.signout();
              }
            }
            break;
          default:
            handleError('Response error', err);
            navigate(URL_SETTINGS.ERROR.url);
            break;
        }
      } else {
        handleError('Network error', err);
        navigate(URL_SETTINGS.ERROR.url);
      }

      return Promise.reject(err);
    }
  );
};
