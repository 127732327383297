import { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { Logger } from '@adatree/atomic-components';
import { handleError, trackError } from '../../../utils/errors/errors.util';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import isEmail from 'validator/lib/isEmail';

interface OtpSignInEmailProps {
  onSuccess: (email: string) => void;
}

export const OtpSignInEmail = (props: OtpSignInEmailProps) => {
  const navigate = useNavigate();
  const { onSuccess } = props;
  const { appSettings } = useAppSettings();
  const [alertMsg, setAlertMsg] = useState('Please enter your email address to receive a One-time Password.');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [fetching, setFetching] = useState(false);

  const handleSubmitEmail = async () => {
    if (!isEmail(email)) {
      setEmailError(true);
      setEmailErrorMsg('Please enter a valid email.');
    } else {
      setFetching(true);

      const url = `https://${appSettings.api.backendDomain}/otp`;
      const data = {
        email: email,
      };

      try {
        await axios.post(url, data);
        setAlertMsg(`We have sent a One-time Password to ${email}`);
        setFetching(false);
        onSuccess(email);
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleErrors = (error) => {
    setFetching(false);

    if (error.response) {
      Logger.error('Server Error calling OTP', error.response);
      trackError(error);
      navigate(URL_SETTINGS.ERROR.url);
    } else if (error.request) {
      handleError('Client Error calling OTP', error.request);
      trackError(error);
      navigate(URL_SETTINGS.ERROR.url);
    } else {
      handleError('Error calling OTP', error);
      trackError(error);
      navigate(URL_SETTINGS.ERROR.url);
    }
  };

  return (
    <>
      <Typography variant="h1" data-testId="otp-header">
        Sign in
      </Typography>
      <Typography sx={{ mt: 3, textAlign: 'center' }} data-testId="otp-sub-header">
        {alertMsg}
      </Typography>
      <TextField
        sx={{ my: 2, width: '100%' }}
        id="email"
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        helperText={emailErrorMsg}
        error={emailError}
        onChange={(e) => setEmail(e.target.value)}
        onFocus={() => {
          setEmailError(false);
          setEmailErrorMsg('');
        }}
      />
      <LoadingButton
        sx={{ m: 2, width: '100%' }}
        loading={fetching}
        color="button"
        variant="contained"
        onClick={handleSubmitEmail}
      >
        Send One-time Password
      </LoadingButton>
    </>
  );
};
