import { useCallback } from 'react';
import { APP_FLAGS } from '../../consts/app.flags';

export const useFeatureFlag = (): [(key: APP_FLAGS) => boolean, (key: APP_FLAGS, value: boolean) => void] => {
  const getFlag = useCallback((key: APP_FLAGS) => {
    const strValue = localStorage.getItem(`${key}`);

    if (strValue === 'true') {
      return true;
    } else {
      return false;
    }
  }, []);

  const setFlag = useCallback((key: APP_FLAGS, value: boolean) => {
    localStorage.setItem(`${key}`, `${value}`);
  }, []);

  return [getFlag, setFlag];
};
