const storageKeysPrefix = 'adt_';

const useCase = {
  features: {
    deIdentification: 'DE_IDENTIFICATION',
    multiConsent: 'MULTI_CONSENT',
  },
};

export const CONSTANTS = {
  storageKeys: {
    returnUri: `${storageKeysPrefix}return_uri`,
    authConsentId: `${storageKeysPrefix}auth_consent_id`,
    authState: `${storageKeysPrefix}auth_state`,
    authType: `${storageKeysPrefix}auth_type`,
    consentFlowType: `${storageKeysPrefix}consent_flow_type`,
    otpAuthManager: `${storageKeysPrefix}otp_auth_manager`,
    createConsentRedirect: `${storageKeysPrefix}ccr`,
    manageConsentRedirect: `${storageKeysPrefix}mcr`,
    appErrorRedirect: `${storageKeysPrefix}aer`,
    allowMultiConsent: `${storageKeysPrefix}${useCase.features.multiConsent.toLowerCase()}`,
  },
  consentFlowType: {
    create: 'CREATE',
    edit: 'EDIT',
  },
  urlParamsKeys: {
    consentId: 'cid',
    createConsentRedirect: 'ccr',
    dataHolderId: 'dhi',
    manageConsentRedirect: 'mcr',
    appErrorRedirect: 'aer',
    useCaseId: 'uci',
  },
  useCase: useCase,
  errors: {
    userOptOut: {
      code: 'usr_opt_out',
      description: 'User manually opted out of consent',
    },
    userDataHolderNotListed: {
      code: 'usr_dh_not_listed',
      description: 'User has clicked the data holder is not listed option and cannot proceed with the consent flow',
    },
  },
};
